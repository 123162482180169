<template lang="pug">
     v-container
        template(v-if="selectedNetwork")
            v-card.pointer(v-for="tradePoint in tradepoints" :key="tradePoint.id" class="mx-auto mb-1 pointer" max-width="400" @click.native="goToTradePoint(tradePoint)")
                v-card-title {{ tradePoint.address }}

        v-card(v-if="loading" class="mx-auto" flat max-width="50")
            v-progress-circular(:size="50" color="primary" indeterminate )

        v-card(v-if="error" class="mx-auto" max-width="400")
            v-card-title Что-то пошло не по плану
                v-spacer
                v-icon(color="red") mdi-alert
            v-card-subtitle {{ error }}



</template>


<script>
    import { networkService, tradepointService } from '@/_services'
    import { mapMutations, mapState } from "vuex";

    export default {
        data: () => ({
            loading:false,
            error: null,
            tradepoints:[]
        }),

        mounted() {
            if (!this.selectedNetwork) this.loadNetwork()
            else {
                this.setAppBarTitle(this.selectedNetwork.name)
            }
            this.loadTradepoints()
        },
        destroyed() {
            this.setSelectedNetwork(null)
        },

        computed: {
            ...mapState("project", ["selectedProject"]),
            ...mapState("network", ["selectedNetwork"]),
            ...mapState("pointreport", ["selectedPoint"])
        },
        methods: {
            ...mapMutations(['setAppBarTitle']),
            ...mapMutations("project", ["setSelectedProject"]),
            ...mapMutations("network", ["setSelectedNetwork"]),
            ...mapMutations("pointreport", ["setSelectedPoint"]),

            loadNetwork(){
                this.setAppBarTitle('loading')
                networkService.loadNetwork(this.$route.params.nid)
                    .then(data => {
                        this.setSelectedNetwork(data)
                        this.setAppBarTitle(this.selectedNetwork.name)
                    })
                    .catch(error => {
                        this.setAppBarTitle(this.error)
                    })
            },

            loadTradepoints(){
                this.loading = true
                tradepointService.getmytradepoints(this.$route.params.nid)
                    .then(data => {
                        this.tradepoints = data
                        this.loading = false
                    })
                    .catch(error => {
                        this.error = error
                        this.loading = false
                    })
            },

            goToTradePoint(tp){
                this.setSelectedPoint(tp)
                this.$router.push(`/project/${this.$route.params.id}/network/${this.$route.params.nid}/tradepoint/${tp.id}`)
            }
        }
    }
</script>